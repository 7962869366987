embedpano({swf:"js/dependencies/KRPano/krpano.swf", xml:"js/dependencies/KRPano/viewer/homepage.xml", bgcolor: "#151515", target:"home-panorama", html5:"auto", mobilescale:1.0, passQueryParameters:true
});

window.sr = ScrollReveal({ reset: true, container: '#body', scale: 1, duration: 500, mobile: true, distance: '60px', reset: false });
sr.reveal('#gadget-monitor', { 
    origin: 'bottom', 
    delay: 500,
});
sr.reveal('#gadget-vr', { 
    origin: 'bottom', 
    delay: 200,
});
sr.reveal('#gadget-ipad', { 
    origin: 'left', 
    delay: 100,
});
sr.reveal('#gadget-laptop', { 
    origin: 'bottom', 
    delay: 300,
});
sr.reveal('#gadget-mobile', { 
    origin: 'left', 
    delay: 400,
});
sr.reveal('#animation-text1', { 
    origin: 'right', 
    delay: 200,
});
sr.reveal('#animation-text2', { 
    origin: 'right', 
    delay: 400,
});
sr.reveal('#animation-button1', { 
    origin: 'right', 
    delay: 500,
});
sr.reveal('#animation-button2', { 
    origin: 'right', 
    delay: 600,
});
sr.reveal('#step-title', { 
    scale: .5,
    delay: 200,
});
sr.reveal('#step1', { 
    scale: .5,
    delay: 300,
});
sr.reveal('#step2', { 
    scale: .5,
    delay: 400,
});
sr.reveal('#step3', { 
    scale: .5,
    delay: 500,
});


$(window).on('load', function(){
    $('.vrExamples iframe').attr('src', 'https://viara.co/adminadmin1/homepage?iframe=panorama');
});

(function ($) {
                
    //Function that determines whether the jquery object is on screen
    $.fn.isOnScreen = function(aCallback){

        //Get the width and height of the element
        var height = this.outerHeight();
        var width = this.outerWidth();

        //If we fail to get either, bail
        if(!width || !height){
            return false;
        }

        //Grab the window
        var win = $(window);

        //Determine the top and left of the viewports
        var viewport = {
            top : win.scrollTop(),
            left : win.scrollLeft()
        };

        //Calculate the right and bottom of the viewport
        viewport.right = viewport.left + win.width();
        viewport.bottom = viewport.top + win.height();

        //Determine the bounds of this element
        var bounds = this.offset();
        bounds.right = bounds.left + width;
        bounds.bottom = bounds.top + height;

        //Determine what part of the element is showing
        var showing = {
            top : viewport.bottom - bounds.top,
            left: viewport.right - bounds.left,
            bottom: bounds.bottom - viewport.top,
            right: bounds.right - viewport.left
        };

        //Determine if the element is showing within the viewport
        var result = showing.top > 0
            && showing.left > 0
            && showing.right > 0
            && showing.bottom > 0;

        //Callback for convienience
        if(typeof aCallback == 'function') {
            return aCallback(showing, result);
        }

        //Return result
        return result;
    };

    //Returns the distance between the current elements top and the viewports top
    $.fn.distanceFromViewportTop = function(aOffset, aCallback) {

        //Get the width and height of the element
        var height = this.outerHeight();
        var width = this.outerWidth();

        //If we fail to get either, bail
        if(!width || !height){
            return false;
        }

        //Grab the window
        var win = $(window);

        //Determine the top and left of the viewports
        var viewport = {
            top : win.scrollTop(),
            left : win.scrollLeft()
        };

        //Calculate the right and bottom of the viewport
        viewport.right = viewport.left + win.width();
        viewport.bottom = viewport.top + win.height();

        //Determine the bounds of this element
        var bounds = this.offset();
        bounds.right = bounds.left + width;
        bounds.bottom = bounds.top + height;

        //Calculate the distance from the top of the element to the top of the viewport
        var distance = bounds.top - viewport.top - aOffset;

        //Callback for convienience
        if(typeof aCallback == 'function') {
            return aCallback(distance);
        }

        //Return result
        return distance;
    };


    function animation1(){
        //Homepage - Top animation
        /*$( ".animation1" ).distanceFromViewportTop(100, function e(distance) {

            if (distance < 0) distance = 0;

            var h = $( ".animation1" ).innerHeight();

            //from { background-position: 3em 760px, 0 760px, right -650px, right -650px; }
            //to { background-position: 3em 60px, left bottom, 1% right, right 0; }

            var r1 = 60 + distance;
            var r2 = 0 - distance;
            var r3 = -90 - distance;
            var r4 = h - (h - distance);

            var test = '3em ' + r1 + 'px, left ' + r2 + 'px, 70% ' + r3 + 'px, right ' + r4 + 'px';

            $( ".animation1" ).css('background-position', test);

        });*/
    }

    function animation2(){
        //Homepage - Bottom animation
        /*$( ".animation2" ).distanceFromViewportTop(100, function e(distance) {

            if (distance < 0) distance = 0;

            var h = $( ".animation2" ).innerHeight();

            //from { background-position: 3em 760px, 0 760px, right 665px, right 665px; }
            //to { background-position: left 300px, left 0px, right 200px, right 200px; }

            var r1 = h - (h - distance) + 300;
            var r2 = h - (h - distance);
            var r3 = h - (h - distance) + 200;
            var r4 = h - (h - distance) + 200;

            var test = 'left ' + r1 + 'px, left ' + r2 + 'px, right ' + r3 + 'px, right ' + r4 + 'px';

            $( ".animation2" ).css('background-position', test);

        }); */
    }


    animation1();
    $(window).scroll(function(){
        animation1();
        animation2();
    });

})(jQuery);