$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

/**
 * show loader for page
 */
function showLoader() {
    var div = document.getElementById('container-loader');
    if( div != null ) document.body.removeChild(div);

    var div = document.createElement('div');
    div.setAttribute("id", "container-loader");
    var loader = document.createElement('div');
    loader.classList.add('loader');
    div.appendChild(loader);
    document.body.appendChild(div);
}
/**
 * hide loader for page
 */
function hideLoader() {
    var div = document.getElementById('container-loader');
    document.body.removeChild(div);
}

//TODO
$(function(){
    $('.ui.modal').modal({
        transition: 'fade',
        duration: 200
    });
    $('.ui.dropdown').dropdown({
        transition: 'fade'
    });

    $('#loginModal')
        .modal('attach events', '.loginBtn', 'show');

    $('#signupModal')
        .modal('attach events', '.signupBtn', 'show');

    $('#paswordResetModal')
        .modal('attach events', '.passwordResetBtn', 'show')
        .modal('attach events', '#cancelReset', 'hide');

    $('.ui.checkbox').checkbox();
    $('[data-toggle=popup]').popup({
        transition: 'fade'
    });
    $('#mobile-menu').click(function(){
        $('#mobile-sidebar').sidebar('toggle');
    });
});





jQuery.toastify = function(title, description, type) {
    $('.toastify').remove();
    var modal = $('<div class="ui message mini modal toastify"><i class="close icon"></i></div')
        .appendTo(document.body);
    var header = $('<strong class="font-size-medium margin-bottom half"></strong')
        .appendTo(modal);
    var content = $('<p class="font-size-normal"></p')
        .appendTo(modal);

    modal.addClass(type);
    header.text(title);
    content.text(description);
    modal.modal('show');
}


$('.ui.form.animated .input').on('focus', function(e){
    e.target.parentElement.classList.add("focusWithText");
});

$('.ui.form.animated .input').on('blur', function(e){
    if(e.target.value == "") {
        e.target.parentElement.classList.remove("focusWithText");
    }
});





